import { REGEX } from 'constants/common';
import FloatInput from 'features/common/floatInput/FloatInput';
import { Form } from 'antd';
import React from 'react';
import './style.less';

/**
 * @method BankDetails
 * @description bank details form component
 * @returns {JSX.Element}
 */

const BankDetails: React.FC = (): JSX.Element => {

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e = e || window.event;
    const key = e.which || e.keyCode;

    const command = e.metaKey ? e.metaKey : key === 91 ? true : false;
    const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;

    // allowing only pasted value ,right-left arrows and enter key
    if ((key === 86 && ctrl) || (key === 86 && command) || key === 37 || key === 39 || key === 13) {
        return;
    }

    // allowing only number keys
    if (!e.key.match(REGEX.NUMBER_ONLY) && e.keyCode !== 8) {
        e.preventDefault();
        e.stopPropagation();
    }
};

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = parseInt(e.clipboardData?.getData("Text"));

    if (isNaN(pastedValue)) {
        e.preventDefault();
        e.stopPropagation();
    }
};

const swiftIcon = (
  <>
  <img src={`${process.env.PUBLIC_URL}/images/swift-code.png`} width={'14px'} height={'14px'} alt="" />
  </>
);

  return (
    <div className="bankdetails" data-testid="bankdetails">
      <h3>Bank Details</h3>
      <div className="bankdetails-first-section">
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="bank_account_nunber"
        >
          <FloatInput
            maxLength={25}
            label="Account Number"
            placeholder="Account Number"
            onKeyDown={handleKeyDown} 
            onPaste={handlePaste}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="bank_name"
          className="bank-name"
        >
          <FloatInput label="Bank Name" maxLength={50}/>
        </Form.Item>
      </div>
      <div className="bankdetails-second-section">
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="bank_swift_code"
        >
          <FloatInput 
            label="Swift Code" 
            maxLength={25}
            suffix={swiftIcon}
            placeholder="Swift Code" 
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="bank_city_name"
          className="city-name"
        >
          <FloatInput label="City" maxLength={50} placeholder="City" />
        </Form.Item>
      </div>
    </div>
  );
};

export default BankDetails;
