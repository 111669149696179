import { PAGE_URLS } from 'constants/common';
import HomeHeader from 'features/common/pageHeader/PageHeader';
import ChangePassword from 'features/settings/changePassword/ChangePassword';
import Profile from 'features/settings/profile/Profile';
import { Tabs } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.less';

/**
 * @method Settings
 * @description settings page
 * @returns {JSX.Element}
 */

const Settings: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeKey = pathname === PAGE_URLS.SETTINGS ? '1' : '2';
  
  const handleTabsClick = (key: any) => {
    if (key && key === '2') {
      navigate(PAGE_URLS.SETTINGS_CHANGE_PASSWORD);
    } else if (key && key === '1') {
      navigate(PAGE_URLS.SETTINGS);
    }
  };

  return (
    <div data-testid="settings-page">
      <HomeHeader heading="Profile Settings">
      <div className='profile-settings-tab'>
        <Tabs activeKey={activeKey} onTabClick={handleTabsClick}>
          <Tabs.TabPane tab="Profile" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="Change Password" key="2"></Tabs.TabPane>
        </Tabs>
      </div>
      </HomeHeader>
      <div className="app-container dashboard">
        {pathname === PAGE_URLS.SETTINGS && <Profile />}
        {pathname === PAGE_URLS.SETTINGS_CHANGE_PASSWORD && <ChangePassword />}
      </div>
    </div>
  );
};

export default Settings;
