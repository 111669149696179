import { Col, Form, message, Modal, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import OrderSummary from '../orderSummary/OrderSummary';
import { topupPaymentForm, fetchTopupFormData } from '../TopUpAPI';
import TopupForm from '../topupForm/TopupForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './style.less'
import { PAGE_URLS, PAYMENT_TYPES } from '@constants/common';
import { useNavigate } from 'react-router-dom';
import { FormData } from 'interfaces/common.interface';

/**
 * @method AddNewTopup
 * @description AddNewTopup Component for topup page
 * @returns {JSX.Element}
 */

const AddNewTopup: React.FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [, setFormValues] = useState<any>();
  const [currency, setCurrency] = useState<number>();
  const [formData, setFormData] = useState<FormData | any>();
  const [currencyIso, setCurrencyIso] = useState<string>()
  const [buttonStatus, setButtonStatus] = useState<boolean>(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState<number>(3);
  const [selectedPaymentCode, setSelectedPaymentCode] = useState('')
  const amount = form.getFieldValue('amount_in_currency');
  const navigate = useNavigate();

  const handleOrderSummary = useCallback( (value: any, allValues: any) => {
    setFormValues(allValues);
    setSelectedPaymentType(allValues.payment_type);
  },[]);

  const handlePaymentCode = (code: string) => {
    setSelectedPaymentCode(code);
  }

  const handleCurrencyId = useCallback((value: number, options: any): void  => {
    setCurrency(value);
    setCurrencyIso(options.children);
  },[]); 

  const handleFormInitialData = async ()=> {
    const res: any = await fetchTopupFormData();
    if(res.ok){
      setFormData(res.data?.data);
      setCurrencyIso(res.data?.data?.currencies[0]?.iso_code);
      setCurrency(res.data?.data?.currencies[0]?.id);
      form.setFieldsValue({
        corporate_name : res.data?.data?.corporate_name,
      });
    }
  }

  const handleFormSubmit = async (values: any) => {
    Modal.confirm({
      title: 'Confirm',
      className: 'confirm-modal',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to submit?',
      onOk: async () => {
        setLoading(true);        
        const formData = {
          currency: currency,
          amount_in_currency: values.amount_in_currency,
          reference_id: values.reference_id,
          payment_type: values.payment_type,
          ...(selectedPaymentCode === PAYMENT_TYPES.BANK_TRANSFER && {
            bank_account_details: JSON.stringify({
              bank_account_number: values.bank_account_nunber,
              bank_name: values.bank_name,
              bank_swift_code: values.bank_swift_code,
              bank_city_name: values.bank_city_name,
            }),
          }),
          ...(selectedPaymentCode === PAYMENT_TYPES.CHEQUE && {
            cheque_details: JSON.stringify({
              cheque_number: values.cheque_number,
              cheque_date: values.cheque_date,
              cheque_bank_name: values.cheque_bank_name,
              cheque_person_name: values.cheque_person_name,
            }),
          }),
        };
        
        const res = await topupPaymentForm(formData);
        if(res.ok){
          message.success(res.data?.message);
          setButtonStatus(true);
          form.resetFields();
          navigate(PAGE_URLS.TOPUP);
        }else{
          message.error(res.data?.message);
          if (res?.data.errors?.reference_id?.length) {
            form.setFields([{ name: "reference_id", errors: res.data.errors?.reference_id }]);
          }
          if (res.data.errors?.amount_in_currency?.length){
            form.setFields([{name: "amount_in_currency", errors: res.data.errors?.amount_in_currency}])
          }
        }
        setLoading(false);
      },
    });
    
  };

  return (
    <div className='add-topup' data-testid="add-topup">
      <Form
        form={form}
        onValuesChange={handleOrderSummary}
        onFinish={handleFormSubmit}
      >
        <Row gutter={36}>
          <Col span={18}>
            <div className="topup-form">
              <TopupForm form={form} handleCurrencyId={handleCurrencyId} handleFormInitialData={handleFormInitialData} formData={formData} handlePaymentCode={handlePaymentCode} selectedPaymentCode={selectedPaymentCode}/>
            </div>
          </Col>

          <Col span={6}>
            <div className="topup-order-summary">
              <OrderSummary amount={{ amount_in_currency: amount}} selectedPaymentType={selectedPaymentType}  isLoading={isLoading} buttonStatus={buttonStatus} currency={currency} currencyIso={currencyIso}/>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddNewTopup;
