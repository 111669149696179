import { useParams, useNavigate } from 'react-router-dom';
import PublicLayout from "layout/publicLayout";
import './style.less';
import { PAGE_URLS } from 'constants/common';

/**
 * @method ErrorPage
 * @description error page
 * @returns {JSX.Element}
 */

export default function ErrorPage(): JSX.Element {
    const { errorCode } = useParams();
    const navigate = useNavigate();

    return (
        <div data-testid="error-page">
        <PublicLayout>
            <div className='error-page'>
            <div className='error-page-image'>
               {(!errorCode || errorCode === '404') && <img src={`${process.env.PUBLIC_URL}/images/404.png`} alt="404 Error" data-testid="403Icon"/>}
               {errorCode === '403' && <img src={`${process.env.PUBLIC_URL}/images/403.png`} alt="403 Error" />}
               {errorCode === '500' && <img src={`${process.env.PUBLIC_URL}/images/500.png`} alt="500 Error" />}
            </div>
            <div className='error-page-content'>
                {(!errorCode || errorCode === '404') && (
                        <>
                            <h3>Oops! No Gift Cards Here.</h3>
                            <p>The page you requested was not found, and we have a ﬁne<br />guess why. If you typed the URL directly, please make sure the <br />spelling is correct.</p>
                        </>

                    )}
                {errorCode === '403' && (
                    <>
                    <h3>Sorry! Access Denied.</h3>
                    <p>You may not have permission or limited-access for the page<br/>you’ve requested. Please contact the team for details.</p>
                    </>
                )}
                {errorCode === '500' && (
                    <>
                    <h3>Oops! Can’t connect to the server.</h3>
                    <p>A server error occurred with the page you requested, we have<br/>informed the team of it, try to refresh the page or try again<br/>later. Thanks.</p>
                    </>
                )}
               
            </div>
            <div className='error-page-button'>
                <button><img src={`${process.env.PUBLIC_URL}/images/error-back.png`} onClick={() => navigate(-1)} alt="" data-testid="back-button"/></button>
                <button><img src={`${process.env.PUBLIC_URL}/images/error-home.png`} onClick={() => navigate(PAGE_URLS.LOGIN)} alt="" data-testid="home-button"/></button>
                {errorCode === '500' && <button> <img src={`${process.env.PUBLIC_URL}/images/error-refresh.png`} onClick={() => navigate(-1)} alt="" /> </button>}
            </div>
            </div>
        </PublicLayout>
        </div>
    );
}