import API_URLS from 'constants/apiUrls';
import fetchAPI from 'utils/fetcher';

export async function fetchApiCredentials(page: number = 1): Promise<any> {
  const url = `${API_URLS.GET_APICREDENTIAL}?page=${page}`
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  return await fetchAPI(url, options, true);
}

export async function createApiKey() {

  const options = {
    method: 'POST',
    credentials: 'include',
  };

  return await fetchAPI(API_URLS.GET_APICREDENTIAL, options, true);
}
