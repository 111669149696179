import React from 'react'
import { Layout } from 'antd';
import Footer from '../../features/common/footer';

import './style.less'

const { Content } = Layout;

interface LayoutProps {
    heading?: String;
    children: React.ReactElement;
    alignTop?: boolean
}

const PublicLayout: React.FC<LayoutProps> = ({ heading, children, alignTop }) => {
    return (
        <div data-testid="public-layout">
        <Layout className='public-layout'>
            <Layout.Header>
                {heading ? <h1>{heading}</h1> : <img className='public-layout--logo' src={`${process.env.PUBLIC_URL}/images/ygag-logo.png`} alt="YouGotaGift Logo" />}
            </Layout.Header>
            <Content className={alignTop ? "align-top" : ""}>
                {children}
            </Content>
            <Footer />
        </Layout>
        </div>
    )
}

export default PublicLayout;