import { Card } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import ApiContents from '../apiContents/ApiContents';
import './style.less';

/**
 * @method CreateApi
 * @description create api Component
 * @returns {JSX.Element}
 */

const CreateApi: React.FC = (): JSX.Element => {
  const { state: routeState }: any = useLocation();

  return (
    <div className="create-api" data-testid="create-api">
      
      <Card className="create-api-card">
        <div className="create-api-card-header">
          <span>New Credentials</span>
        </div>
        <div className="create-api-card-message">
          <p>
            API Key regenerated Successfully.
            <br />
            Please use the below key for all API request from now.
          </p>
        </div>
        <div className="create-api-card-date">
          <span>Expiry Date:</span> {routeState?.expires_on}
        </div>
        <ApiContents  api={routeState?.api_key} title={"API Key"} /> 
        <ApiContents  api={routeState?.api_secret} title={"API Secret"} />
        <div className="create-api-card-highlight">
          <span>*Copy the credentials without fail.</span>
        </div>
      </Card>
    </div>
  );
};

export default CreateApi;
