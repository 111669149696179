import { fetchCorporateBalance } from 'features/home/HomePageAPI';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import './style.less';

/**
 * @method AvailableBalance
 * @description balance showing component for page header
 * @returns {JSX.Element}
 */

const AvailableBalance: React.FC = (): JSX.Element => {
  const [balance, setBalance] = useState<any>({});

  useEffect(() => {
    if (!balance.corporate_balance) {
      handleCorporateBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleCorporateBalance = async () => {
    const res = await fetchCorporateBalance();
    if (res.ok) {
      setBalance(res.data.data);
    }
  };

  return (
    <div className="home-header-wallet-details" data-testid="available-balance">
      <img src={`${process.env.PUBLIC_URL}/images/wallet.png`} alt="wallet" />
      <div className="home-header-wallet-details-balence">
        <span>Available Balance</span>
        {!balance.corporate_balance && <Skeleton.Button size="small" active />}
        {balance.corporate_balance && <span>{balance.corporate_balance}</span>}
      </div>
    </div>
  );
};

export default AvailableBalance;
