import API_URLS from "constants/apiUrls";
import fetchAPI from "utils/fetcher";

export async function fetchBrandListing(): Promise<any> {
  const options = {
    method: "GET",
    credentials: "include",
  };

  return await fetchAPI(API_URLS.BRAND_LISTING, options, true);
}
