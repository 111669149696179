import { Button, Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react'
import { updatePassword } from '../SettingsAPI';
import './style.less';
import { logoutUser } from '@features/common/authAPI';
import { setIsLoggedIn } from '@features/common/authSlice';
import { useAppDispatch } from '@redux/store';

/**
 * @method ChangePassword
 * @description Profile component
 * @returns {JSX.Element}
 */

const ChangePassword: React.FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleLogoutResponse = async () => {
    try {
      setSubmitting(true);
      const res = await logoutUser();
      if (res.ok) {
        dispatch(setIsLoggedIn(false));
      } else {
        message.error(res.data.message || 'Something went wrong.');
      }
      setSubmitting(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handlePasswordUpdate = async (values: any) => {
    setSubmitting(true);
    const res = await updatePassword(values);
    setSubmitting(false);
    if (!res?.ok) {
      if (res?.data?.errors?.old_password) {
        form.setFields([
          { name: 'old_password', errors: res.data?.errors?.old_password },
        ]);
      } else {
        Modal.error({
          width: 468,
          className: 'modal-dialogs',
          title: 'Change Password',
          content:
            res?.data?.errors?.non_field_errors ||
            res?.data?.errors?.password ||
            res?.data?.errors?.new_password1 ||
            res?.data?.errors?.new_password2,
          centered: true,
        });
      }
    } else {
      message.success(res.data?.message, 2);
      setTimeout(async () => {
        await handleLogoutResponse();
      }, 2000);
      form.resetFields();
    }
  };
  
  return (
    <div className='change-password' data-testid="change-password">
      <Form
      form={form}
      onFinish={handlePasswordUpdate}
      >
        <div className='change-password-first-section'>
        <Form.Item 
        name="old_password"
        rules={[{ required: true, message: 'Current password is required.' }]}
        >
          <Input.Password placeholder='Current Password'  className='settings-float-input'/>
        </Form.Item>
        </div>
        <div className='change-password-second-section'>
        <Form.Item 
        name="new_password1"
        rules={[{ required: true, message: 'New password is required.' }]}
        >
        <Input.Password placeholder='New Password' className='settings-float-input'/>
        </Form.Item>
        <Form.Item 
        name="new_password2"
        dependencies={['new_password1']} 
        rules={[
          { required: true, message: "Confirm password is required!" },
          ({ getFieldValue }) => ({
              validator(_, value) {
                  if (!value || getFieldValue('new_password1') === value) {
                      return Promise.resolve();
                  }
                  return Promise.reject(new Error("Two passwords that you entered is inconsistent!"));
              },
          }),
      ]}
        className='confirm-password'
        >
        <Input.Password placeholder='Confirm Password' className='settings-float-input'/>
        </Form.Item>
        </div>
        <Button loading={isSubmitting} htmlType="submit" type='primary'>Save Changes</Button>
      </Form>
    </div>
  )
}

export default ChangePassword;