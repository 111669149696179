import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "@redux/store";

export interface AuthSlice {
    oauthToken: string,
    jwtToken: string,
    isLoggedIn: boolean,
    isResendOTPEnabled: boolean,
    isAuthenticating: boolean,
}

const initialState: AuthSlice = {
    oauthToken: '',
    jwtToken: '',
    isLoggedIn: false,
    isResendOTPEnabled: false,
    isAuthenticating: true,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setOauthToken: (state, action: PayloadAction<string>) => {
            state.oauthToken = action.payload;
        },
        setJwtToken: (state, action: PayloadAction<string>) => {
            state.jwtToken = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setResendOTPEnabled: (state, action: PayloadAction<boolean>) => {
            state.isResendOTPEnabled = action.payload;
        },
        setIsAuthenticating: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticating = action.payload;
        },
    },
});

export const { setOauthToken, setJwtToken, setIsLoggedIn, setResendOTPEnabled, setIsAuthenticating } = authSlice.actions;
export const getOauthTokenValue = (state: AppState) => state.auth.oauthToken;
export default authSlice.reducer;