import API_URLS from "constants/apiUrls";
import fetchAPI from "utils/fetcher";
import jsonToFormData from "utils/jsonToFormData";

export async function fetchTopupHistoryTable(): Promise<any> {
  
  const options={
    method : 'GET',
    credentials:'include'
  }

  return await fetchAPI(API_URLS.TOPUP_HISTORY, options, true);
}

export async function fetchTopupFormData(): Promise<any>{

  const options={
    method : 'GET',
    credentials:'include'
  }

  return await fetchAPI(API_URLS.CREATE_TOPUP, options, true);
}

export async function topupPaymentForm(data: any): Promise<any>{
  const body = jsonToFormData(data);
  
  const options={
    method : 'POST',
    credentials:'include',
    body
  }

  return await fetchAPI(API_URLS.CREATE_TOPUP, options, true);
}