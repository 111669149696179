import API_URLS from "constants/apiUrls";
import fetchAPI from "utils/fetcher";
import jsonToFormData from "utils/jsonToFormData";

export async function orderResendDetails(data: any): Promise<any> {
  const body = jsonToFormData(data);

  const options={
    method : 'POST',
    credentials : 'include',
    body
  }

  return await fetchAPI(API_URLS.ORDER_RESEND_DETAILS, options, true);
}

export async function orderResend(data: any): Promise<any> {
  const body = jsonToFormData(data);

  const options={
    method : 'POST',
    credentials : 'include',
    body
  }

  return await fetchAPI(API_URLS.ORDER_RESEND, options, true);
}