import React, { useState } from "react";
import { Input } from "antd";
import './style.less'

import FloatSelect from "./FloatSelect";


interface FloatInputProps {
  label?: string,
  value?: string,
  placeholder?: string,
  type?: string,
  prefix?: any,
  isHighlighted?: string,
  className?: string,
  defaultValue?: any,
  onChange?: any
  disabled?: boolean
  onPaste?: (e: any) => void,
  onKeyDown?: (e: any) => void,
  maxLength? : number,
  suffix? : any
  name?: any
}

/**
 * @method FloatInput
 * @description common FloatInput component
 * @returns {JSX.Element}
 */

export const FloatInput: React.FC<FloatInputProps>  = (props): JSX.Element  => {
  const [, setFocus] = useState(false);
  let { label, value, placeholder, isHighlighted, prefix, className, ...restProps } = props;

  if (!placeholder) placeholder = label;

  const labelClass = "label as-label" 
  const FontCheck = label === 'Corporate Name' ? 'float-label-active' : '';
  const sizeSettings = className === "settings-float-input" ? 'float-label-width float-label' : 'float-label';
  

  return (
    <div
      data-testid="float-input"
      className={sizeSettings}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input autoComplete="none" width={254} {...restProps} placeholder={placeholder} className={FontCheck} size="large" value={value} />
      <label className={labelClass}>
        {label}
      </label>
    </div>
  );
};



export default FloatInput;
export { FloatSelect };