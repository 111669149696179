import fetchAPI from "utils/fetcher";
import API_URLS, { API_BASE_URL } from "constants/apiUrls";
import jsonToFormData from "utils/jsonToFormData";
import { IForgotPasswordForm, ILoginForm, IResetPasswordForm } from "interfaces/common.interface";

export async function logoutUser(){
    const options = {
        method: 'POST',
        credentials: 'include'
    }
    return await fetchAPI(API_URLS.LOG_OUT ,options, true);
}

export async function submitResetPassword(data: IResetPasswordForm, token: string | undefined): Promise<any> {
    const url = `${API_URLS.RESET_PASSWORD_SUBMIT}/${token}/`;
    const body = jsonToFormData(data);
    const options = {
        method: 'POST',
        body
    }
    return await fetchAPI(url, options);
}

export async function requestResetPassword(data: IForgotPasswordForm): Promise<any> {
    const url = API_URLS.RESET_PASSWORD_REQUEST;
    const body = jsonToFormData(data);
    const options = {
        method: 'POST',
        body
    }
    return await fetchAPI(url, options);
}

export async function resendOTP(url: string): Promise<any> {
    const options = {
        method: 'POST'
    }
    return await fetchAPI(url, options, true);
}

export async function verifyOTP(data: any, url: string): Promise<any> {
    const body = jsonToFormData(data)
    const options = {
        method: 'POST',
        credentials : 'include',
        body,
    }

    return await fetchAPI(url, options, true);
}

export async function loginUser(data: ILoginForm): Promise<any> {
    const body = jsonToFormData(data)
    const options = {
        method: 'POST',
        body,
        credentials: 'include'
    }

    return await fetchAPI(API_URLS.LOGIN, options);
}

export const fetchOauthToken = async (): Promise<any> => {
    const body = jsonToFormData({
        client_secret: process.env.REACT_APP_BIZ_REWARDS_OAUTH_CLIENT_SECRET,
        client_id: process.env.REACT_APP_BIZ_REWARDS_OAUTH_CLIENT_ID,
        grant_type: 'client_credentials'
    })
    const options = {
        method: 'POST',
        body
    }

    const url = `${API_BASE_URL}${API_URLS.OAUTH_TOKEN}`
    const res = await fetch(url, options);

    return await res.json();
}

export const fetchRefreshToken = async (): Promise<any> => {
    const res = await fetch(`${API_BASE_URL}${API_URLS.REFRESH_TOKEN}`, {
        method: 'POST',
        credentials: 'include',
    });

    if (!res.ok) {
        return false;
    } else {
        return await res.json();
    }
};