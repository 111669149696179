import { PAGE_URLS } from 'constants/common';
import ApiCredentialsManagment from 'features/apiCredentials/apiCredentialManagment/ApiCredentialsManagment';
import CreateApi from 'features/apiCredentials/CreateApi/CreateApi';
import HomeHeader from 'features/common/pageHeader/PageHeader';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './style.less';

/**
 * @method ApiCredentials
 * @description api credentials Page
 * @returns {JSX.Element}
 */

const ApiCredentials: React.FC = (): JSX.Element => {
  const {pathname} = useLocation();

  return (
     <div data-testid="api-credentials">
      <HomeHeader heading={'API Credentials Management'}/>
      <div className="app-container dashboard">
        <div className='api-credential-managment'>
          {pathname === PAGE_URLS.API_CREDENTIALS && <ApiCredentialsManagment /> }
          {pathname === PAGE_URLS.CREATE_API && <CreateApi />}
          </div>
      </div>
    </div>
  )
}

export default ApiCredentials;