import React, { useEffect, useState } from "react";
import "./style.less";
import HomeHeader from "@features/common/pageHeader/PageHeader";
import { Col, Row } from "antd";
import { BrandListing } from "interfaces/common.interface";
import { fetchBrandListing } from "@features/brands/brandListingAPI";

const Brands = () => {
  const [brandListing, setBrandListing] = useState<BrandListing>();

  useEffect(() => {
    handleOrderListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrderListing = async () => {
    const res = await fetchBrandListing();
    if (res.ok) {
      setBrandListing(res?.data?.data);
    }
  };

  return (
    <>
      <HomeHeader heading="Brands" />
      <div className="app-container dashboard">
        <div className="brands-page">
          <Row>
            <Col span={24}>
              <div className="orders-page-wrapper">
                <iframe
                  title="orders-table"
                  className="brands-page-iframe"
                  src={brandListing && brandListing?.brand_list_url}
                  allowFullScreen
                  width="100%"
                  height="100%"
                  data-testid="orders-table"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Brands;
