import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import commonReducer from "features/common/CommonSlice";
import authReducer from "features/common/authSlice";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
};

const authPersistConfig = {
    key: 'auth',
    storage: storageSession,
    whitelist: ["isResendOTPEnabled", "isLoggedIn", "hasUserViewedAlert"]
}


const rootReducer = combineReducers({
    common: commonReducer,
    auth: persistReducer(authPersistConfig, authReducer)
});

export default persistReducer(persistConfig, rootReducer);
