import { Button, Col, Row} from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import './style.less';
import { PAGE_URLS } from 'constants/common';
import { fetchTopupHistoryTable } from '../TopUpAPI';
import { useAppSelector } from '@redux/store';
import { Link } from 'react-router-dom';
/**
 * @method Home
 * @description TopUp History Component
 * @returns {JSX.Element}
 */

const TopUpHistory: React.FC = (): JSX.Element => {
  const [topupTable, setTopupTable] = useState<any>();
  const [data, setData] = useState();
  const {view_and_add_topup} = useAppSelector(
    (state) => state.common.userData
  );
  useEffect(() => {
    handleTopupHistoryTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleTopupHistoryTable = async () => {
    const res = await fetchTopupHistoryTable();
    if(res.ok){
      setTopupTable(res.data?.data);
    }
  }

  return (
    <>
      <div className="topup-history" data-testid="topup-history">
        <div className="topup-history-header">
          <div className="topup-details">
            <i className="icon icon-topup"></i>
            <div className="topup-details-card-content">
              <p className="reports">Top Up History</p>
              <span className="detailed">Detailed</span>
            </div>
          </div>
          {view_and_add_topup && (
              <Link to={PAGE_URLS.TOPUP_PAGE}>
                <Button type="primary">
                  <PlusCircleOutlined style={{ fontSize: '16px', color: '#fff' }} />
                  Add TopUp
                </Button>
              </Link>
          )}
        </div>
        <div className="topup-history-table">
          <Row>
            <Col span={24}>
              <div className="topup-history-table-wrapper">
              <iframe title='topup-table' className='topup-page-iframe' src={topupTable && topupTable?.topup_history_url} allowFullScreen width="100%" height="100%"></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TopUpHistory;
