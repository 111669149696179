import { setUserPopoverData } from 'features/common/CommonSlice';
import FloatInput from 'features/common/floatInput/FloatInput';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { Button, Form, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { updateProfile } from '../SettingsAPI';
import './style.less';

/**
 * @method Profile
 * @description Profile component
 * @returns {JSX.Element}
 */

const Profile: React.FC = (): JSX.Element => {
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { first_name, last_name } = useAppSelector(
    (state) => state.common.userPopoverData
  );
  const { email } = useAppSelector(
    (state) => state.common.userData
  );
  
  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      first_name : first_name,
      last_name : last_name,
      email: email
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[first_name, last_name, email]);

  const handleProfileUpdate = async(values: any) => {
    setIsDisabled(true);
    setSubmitting(true);
    const res = await updateProfile(values);
    setSubmitting(false);
    if(res.ok){
      dispatch(setUserPopoverData(res.data.data));
      message.success(res.data?.message, 2);
    }
    else if(!res.ok){
       if(res?.data?.errors?.first_name ){
        form.setFields([{ name: "first_name", errors: res.data?.errors?.first_name }]);
      }if(res?.data?.errors?.last_name){
        form.setFields([{ name: "last_name", errors: res.data?.errors?.last_name }]);
      }
    }
    else{
      message.error(res.data?.message, 2);
    }
    setTimeout(() => {
      setIsDisabled(false);
    }, 2100);
  }

  return (
    <div className='profile' data-testid="profile-page">
      <Form
      form={form}
      onFinish={handleProfileUpdate}
      >
        <div className='profile-first-section'>
        <Form.Item
         name="first_name"
         rules={[{ required: true, message: 'First name is required' }]}
         >
        <FloatInput label="First Name" maxLength={25} className='settings-float-input'/>
        </Form.Item>
        <Form.Item
         name="last_name" 
         className='last-name'
         rules={[{ required: true, message: ' Last name is required' }]}
         >
        <FloatInput label="Last Name" maxLength={25} className='settings-float-input'/>
        </Form.Item>
        </div>
        <div className='profile-second-section'>
        <Form.Item name={'email'}>
        <FloatInput label="Email" className='settings-float-input' disabled={true} />
        </Form.Item>
        </div>
        <Button loading={isSubmitting} disabled={isDisabled} type='primary' htmlType="submit">Save</Button>
      </Form>
    </div>
  )
}

export default Profile;