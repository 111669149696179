import API_URLS from 'constants/apiUrls';
import fetchAPI from 'utils/fetcher';

export async function fetchRecentActions(url: string): Promise<any> {
  const options = {
    methods: 'GET',
    credentials: 'include',
  };

  return await fetchAPI(url, options, true);
}

export async function fetchCorporateBalance(): Promise<any> {
  const options = {
    methods: 'GET',
    credentials: 'include',
  };

  return await fetchAPI(API_URLS.CORPORATE_BALANCE, options, true);
}

export async function fetchDashboardViews(): Promise<any> {
  const options = {
    methods: 'GET',
    credentials: 'include',
  };

  return await fetchAPI(API_URLS.DASHBOARD_VIEW, options, true);
}