import API_URLS from "constants/apiUrls";
import fetchAPI from "utils/fetcher";
import jsonToFormData from "utils/jsonToFormData";

export async function updateProfile(data: any): Promise<any>{
  const body = jsonToFormData(data);

  const options={
    method: 'PATCH',
    credentials:'include',
    body
  }

  return await fetchAPI(API_URLS.USER_PROFILE, options, true);
}

export async function updatePassword(data: any): Promise<any>{
  const body = jsonToFormData(data);

  const options={
    method: 'POST',
    credentials:'include',
    body
  }

  return await fetchAPI(API_URLS.CHANGE_PASSWORD, options, true);
}