const API_BASE_URL_QA = `https://bizrewards-bd-469.sit.yougotagift.co`;
export const API_BASE_URL =   process.env.REACT_APP_ENVIRONMENT === "qa" ? `${API_BASE_URL_QA}/corporates/api/v1/` : `${process.env.REACT_APP_BIZ_REWARDS_API_BASE_URL}/corporates/api/v1/`;


enum API_URLS {
  OAUTH_TOKEN = "oauth/token/",
  HELPLINE = "helpline-settings/",
  LOGIN = "login/",
  REFRESH_TOKEN = "token/refresh/",
  RESET_PASSWORD_REQUEST = "reset-password/",
  RESET_PASSWORD_SUBMIT = "reset-password/confirm",
  USER_PROFILE = "profile/",
  LOG_OUT = "logout/",
  RECENT_ACTION = "recent-actions/",
  CORPORATE_BALANCE = "corporate-balance/",
  DASHBOARD_VIEW = "dashboard/",
  ORDER_LISTING = "order-details/",
  TOPUP_HISTORY = "topup-history/",
  CREATE_TOPUP = "create-top-up/",
  ORDER_RESEND = "order-resend/",
  ORDER_RESEND_DETAILS = "order-resend-details/",
  CHANGE_PASSWORD = "change-password/",
  GET_APICREDENTIAL = "api-credential/",
  CORPORATE_LISTS = "corporate-list/",
  SELECT_CORPORATE = "corporate-select/",
  BRAND_LISTING = "brand-list/",
  GET_USER_DATA ="user-details/"
}

export default API_URLS;
