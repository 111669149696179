import { Button, Form } from 'antd';
import './style.less';

/**
 * @method OrderSummary
 * @description OrderSummary component for topup page
 * @returns {JSX.Element}
 */
interface orederSummayProps {
  amount: any;
  buttonStatus: boolean;
  isLoading: boolean;
  selectedPaymentType: any;
  currency: any;
  currencyIso: any;
}

const OrderSummary = (props: orederSummayProps): JSX.Element => {
  const { amount, currencyIso } = props;

  return (
    <div className="order-summary" data-testid="order-summary">
      <h3>Order Summary</h3>
      <div className="order-summary-payment">
        <div className="order-summary-payment-description">
          <span>Payable Amount</span>
        </div>
        <div className="order-summary-payment-amount">
          <span>
            {currencyIso}{' '}
            {amount?.amount_in_currency ? amount?.amount_in_currency : '--'}
          </span>
        </div>
      </div>
      <div className="order-summary-total">
        <span>You Pay</span>
          <span>
            {currencyIso}{' '}
            {amount?.amount_in_currency ? amount?.amount_in_currency : '--'}
          </span>
      </div>
      <span className="inclusive-vat">(Inclusive of VAT)</span>
      <div className="order-summary-button-content">
      <Form.Item>
          <Button
            
            block
            type="primary"
            htmlType="submit"
          >
           Submit
          </Button>
        </Form.Item>
      </div>
      <div className="order-summary-payment-options">
        <span>100% Safe & Secure</span>
        <div className="payment-icons">
          <img src="/images/visa.png" alt="" />
          <img src="/images/master.png" alt="" />
          <img src="/images/verisign.png" alt="" />
          <img src="/images/security.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
