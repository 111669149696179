import React, { useState } from 'react';
import './style.less';

/**
 * @method ApiContents
 * @description copy to clipboard function component and api keys
 * @returns {JSX.Element}
 */

const ApiContents = ({ api, title} : { api: string, title: string }): JSX.Element  => {

  const [isCopied, setIsCopied] = useState(false);

  React.useEffect(() => {
    if (setIsCopied) {
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isCopied]);

  const copyToClipboardApiKey = () => {
    navigator.clipboard.writeText(api);
    setIsCopied(true);
  };

  return (
    <div className='create-api-card-key' data-testid="create-api-card-key">
          <div className='api-key-name'>
          <span>{title}</span>
         
          </div>
          <div className='api-key-data'> 
          <div className={`copied${isCopied ? ' show' : ''}`}>Copied</div>
            <span>{api}</span>
            <img src="/images/copy.png" className='copy-img' alt="copy" onClick={copyToClipboardApiKey}/>
          </div>
        </div>
  )
}

export default ApiContents;