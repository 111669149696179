import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import './style.less';
import HomeHeader from 'features/common/pageHeader/PageHeader';
import { fetchOrderListing } from 'features/orders/OrderListingAPI';
import { OrderListing } from 'interfaces/common.interface';
import OrderAlertMessage from '@features/common/orderAlertMessage/OrderAlertMessage';
import { ALERT_DELAY } from '@constants/common';


/**
 * @method Orders
 * @description Order page component
 * @returns {JSX.Element}
 */

const Orders: React.FC  = (): JSX.Element => {
  const [orderListing, setOrderListing] = useState<OrderListing>();
  const [hasUserViewedAlert, setHasUserViewedAlert] = useState<boolean>(false);

  useEffect(() => {
    handleOrderListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleOrderListing = async () => {
    const res = await fetchOrderListing();
    if(res.ok){
      setOrderListing(res?.data?.data);
    }
  }
  
  useEffect(() => {
    if(!hasUserViewedAlert){
      setTimeout(() => {
        setHasUserViewedAlert(true)
      }, ALERT_DELAY)
    }
  },[hasUserViewedAlert])

  // #. Handle alert message close function
  const handleCloseAlertMessage = (value: boolean) => {
    setHasUserViewedAlert(value)
  }
  
  return (
    <>
    <OrderAlertMessage show={!hasUserViewedAlert} handleCloseAlertMessage={handleCloseAlertMessage}/>
    <HomeHeader heading='Orders' />
    <div className="app-container dashboard">
      <div className="orders-page">
      <Row>
        <Col span={24}>
          <div className='orders-page-wrapper'>
          <iframe title='orders-table' className='order-page-iframe' src={orderListing && orderListing?.order_listing_url} allowFullScreen width="100%" height="100%" data-testid="orders-table"></iframe>
          </div>
        </Col>
      </Row>
      </div>
    </div>
    </>
  )
}

export default Orders;
