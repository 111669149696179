import React from 'react';
import { Select, SelectProps } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

interface FloatInputProps extends SelectProps {
  label?: string;
  value?: any;
  placeholder?: string;
  type?: string;
  prefix?: any;
  isHighlighted?: any;
  children?: any;
}

/**
 * @method FloatSelect
 * @description common FloatSelect component
 * @returns {JSX.Element}
 */

const FloatSelect: React.FC<FloatInputProps> = (props): JSX.Element => {
  const [focus, setFocus] = React.useState(false);
  let { label, value, placeholder, isHighlighted, children, ...restProps } =
    props;
  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';
  const highlightClass = isHighlighted ? 'higlighted' : '';

  return (
    <div
      data-testid="float-select"
      className="float-label float-select"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        {...restProps}
        className={highlightClass}
        size="large"
        value={value}
        suffixIcon={<>
        <CaretDownFilled style={{color: '#000'}}/>
        </>}
      >
        {children}
      </Select>

      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </div>
  );
};

export default FloatSelect;
