import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from 'redux/store';
import Routes from './routes';
import PageLoader from 'features/common/pageLoader/PageLoader';
import 'styles/custom.less';

let persistor = persistStore(store);

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={<PageLoader />} persistor={persistor}>
                <Routes />
            </PersistGate>
        </Provider>
    )
}