import React, { useState } from "react";
import { DatePicker } from "antd";
import './style.less'
import moment from "moment";


interface FloatDateProps {
  label?: string,
  value?: string,
  placeholder?: string,
  type?: string,
  prefix?: any,
  isHighlighted?: string,
  className?: string,
  defaultValue?: any,
  onChange?: any
}

/**
 * @method FloatDate
 * @description common FloatDate component
 * @returns {JSX.Element}
 */

export const FloatDate: React.FC<FloatDateProps>  = (props): JSX.Element  => {
  const [, setFocus] = useState(false);
  let { label, value, placeholder, isHighlighted, prefix, className, ...restProps } = props;

  if (!placeholder) placeholder = label;
  const dateFormat = 'DD/MM/YYYY';
  const labelClass = "label as-label" 
  const FontCheck = label === 'Corporate Name' ? 'float-label-active' : '';
  const sizeSettings = className === "settings-float-input" ? 'float-label-width float-label' : 'float-label';
  
  function disabledDate(current: any) {
    // Can not select days before today
    return moment().add(-1, 'days')  >= current
  }

  return (
    <div
      data-testid="float-date"
      className={sizeSettings}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker format={dateFormat} inputReadOnly={true} placeholder={placeholder} className={FontCheck} {...restProps} disabledDate={disabledDate}/>
      <label className={labelClass}>
        {label}
      </label>
    </div>
  );
};



export default FloatDate;