
import './style.less'

/**
 * @method PageLoader
 * @description Loder Component
 * @returns {JSX.Element}
 */

export default function PageLoader(): JSX.Element {
    return (
        <div className="init-loader" data-testid='page-loader'>
            <img src="/images/ygag-logo.png" width="64" alt="" />
            <div className="page-loader"></div>
        </div>
    );
}