import React from 'react'
import { Layout } from 'antd';
import './style.less';

/**
 * @method Footer
 * @description Footer Component
 * @returns {JSX.Element}
 */

const Footer: React.FC = (): JSX.Element => {
    const currentYear = new Date().getFullYear()
    return (
        <Layout.Footer>
            © 2013 - {currentYear} YouGotaGift.com Ltd. - <a href="https://yougotagift.com/page/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> | <a href="https://yougotagift.com/page/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</a>
        </Layout.Footer>
    )
}

export default Footer;