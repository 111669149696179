import API_URLS from "constants/apiUrls";
import fetchAPI from "utils/fetcher";

export async function fetchOrderListing(): Promise<any>{

  const options={
    method : 'GET',
    credentials: 'include',
  }

  return await fetchAPI(API_URLS.ORDER_LISTING, options, true)
}
