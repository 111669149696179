import { Form } from 'antd';
import React from 'react';
import FloatInput, {
} from 'features/common/floatInput/FloatInput';
import './style.less';
import FloatDate from 'features/common/floatInput/floatDate/FloatDate';
import { REGEX } from 'constants/common';

/**
 * @method ChequeDetails
 * @description cheque details form component
 * @returns {JSX.Element}
 */

const ChequeDetails: React.FC = (): JSX.Element => {

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e = e || window.event;
    const key = e.which || e.keyCode;

    const command = e.metaKey ? e.metaKey : key === 91 ? true : false;
    const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;

    // allowing only pasted value ,right-left arrows and enter key
    if ((key === 86 && ctrl) || (key === 86 && command) || key === 37 || key === 39 || key === 13) {
        return;
    }

    // allowing only number keys
    if (!e.key.match(REGEX.NUMBER_ONLY) && e.keyCode !== 8) {
        e.preventDefault();
        e.stopPropagation();
    }
};

const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = parseInt(e.clipboardData?.getData("Text"));

    if (isNaN(pastedValue)) {
        e.preventDefault();
        e.stopPropagation();
    }
};

  return (
    <div className="chequedetails" data-testid="chequedetails">
      <h3>Cheque Details</h3>
      <div className="chequedetails-first-section">
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="cheque_number"
        >
          <FloatInput label="Cheque Number" maxLength={25} placeholder="Cheque Number" onKeyDown={handleKeyDown} onPaste={handlePaste} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="cheque_date"
          className="payment-date"
        >
          <FloatDate placeholder="DD/MM/YY" label="Payment Date"/>
        </Form.Item>
      </div>
      <div className="chequedetails-second-section">
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="cheque_bank_name"
        >
          <FloatInput label="Bank Name" maxLength={50} placeholder="Demo Bank Name" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'All fields are mandatory' }]}
          name="cheque_person_name"
          className="person-name"
        >
          <FloatInput
            label="name of the person"
            placeholder="EG. Joe Chandler"
            maxLength={50}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default ChequeDetails;
