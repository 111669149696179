import { PAGE_URLS } from 'constants/common';
import FloatInput, { FloatSelect } from 'features/common/floatInput/FloatInput';
import { Form, Input, Select } from 'antd';
import  { useEffect } from 'react';
import { Link } from 'react-router-dom';
import BankDetails from '../bankDetails/BankDetails';
import ChequeDetails from '../chequeDetails/ChequeDetails';
import { FormData } from 'interfaces/common.interface';
import { FormInstance } from 'antd/es/form/Form';
import { PAYMENT_TYPES } from 'constants/common';
import './style.less';

/**
 * @method TopupForm
 * @description form component for topup page
 * @returns {JSX.Element}
 */


interface TopupFormProps {
 form : FormInstance<any>
 handleCurrencyId: (value: number, options: any) => void
 handleFormInitialData:  () => Promise <void>
 handlePaymentCode: (code: string) => void
 selectedPaymentCode: any
 formData: FormData
}



const TopupForm = (props: TopupFormProps): JSX.Element => {

  const { form, formData, handleFormInitialData, handleCurrencyId, selectedPaymentCode, handlePaymentCode } = props;
  

  useEffect(() => {
    if(!formData?.corporate_name){
      handleFormInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

const handleChanges = (e: any) => {
  e.preventDefault();
  e.target.value = e.target.value.replace(/\D/g, '');
  form.setFieldsValue({amount_in_currency : e.target.value});
}

const handlePaymentSelectChange = (value:number, option:any) => {
  form.setFieldValue('payment_type', value);
  handlePaymentCode(option.code);
}

  const selectBefore = (
    <>
    {formData && (
    <Select defaultValue={formData?.currencies[0].id} onChange={(value, options) => handleCurrencyId(value, options)}  className="select-before">
      {formData && formData?.currencies.map(({id, iso_code}: {id:number, iso_code: string}) => (
      <Select.Option value={id} data={iso_code} key={id}>{iso_code}</Select.Option>
      ))}
    </Select>
    )}
    </>
  );

  return (
    <div className="topup-payment-form" data-testid="payment-form">
      <div className="topup-payment-form-header">
        <h3>Enter Details</h3>
        <div>
          <Link to={PAGE_URLS.TOPUP} className="topup-history">
            Top Up History {'>'}
          </Link>
        </div>
      </div>
      <div className="topup-payment-form-container">
          <div className="form-first-section">
              <FloatInput name="corporate_name"  label="Corporate Name" disabled={true} value={formData?.corporate_name}/>
            <Form.Item 
            name="amount_in_currency"
            className="topup-amount" 
            rules={[{ required: true, message: 'All fields are mandatory' }]}>
              <Input  addonBefore={selectBefore} onChange={handleChanges} maxLength={10} placeholder="Enter Top Up Amount" className='input'/>
            </Form.Item>
          </div>

          <div className="form-payment-mode">
            <h3>Payment Mode</h3>
            <div className="form-second-section">
              <Form.Item 
              rules={[{ required: true, message: 'All fields are mandatory' }]}
              name="reference_id">
                <FloatInput label="Reference ID" placeholder="Reference ID"/>
              </Form.Item>
              <Form.Item 
              name="payment_type" 
              rules={[{ required: true, message: 'All fields are mandatory' }]} 
              className="selector"
              >
                <FloatSelect
                label="Select Type" 
                placeholder="Select Type"
                onChange={handlePaymentSelectChange}
                >
                  {formData && formData?.payment_type.map(({id, name, code}: {id:any, name: string, code: string}) => (
                    <Select.Option value={id} key={code} code={code}>
                    {name}
                  </Select.Option>
                  ))}
                </FloatSelect>
              </Form.Item>
            </div>
            {formData && (
              <div className='topup-payment-form-components'>
                {selectedPaymentCode === PAYMENT_TYPES.CHEQUE && <ChequeDetails />}
                {selectedPaymentCode === PAYMENT_TYPES.BANK_TRANSFER && <BankDetails />}
              </div>
            )}
          </div>
      </div>
    </div>
  );
};

export default TopupForm;
