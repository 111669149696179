import { Button, Col, message, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useCallback } from 'react';
import './style.less';
import { PAGE_URLS } from 'constants/common';
import {
  createApiKey,
  fetchApiCredentials,
} from '../APICredentialsManagementAPI';
import { useNavigate } from 'react-router-dom';
import { throttle } from 'lodash';
import { useAppSelector } from '@redux/store';

interface DataType {
  key: React.Key;
  date: string;
  api: string;
  expiry: string;
  status: string;
}

interface CredentialType {
  date_added: string;
  api_key: string;
  expiry_date: string;
  is_active: boolean;
}

/**
 * @method ApiCredentialsManagment
 * @description api credential managment table Component
 * @returns {JSX.Element}
 */

const ApiCredentialsManagment: React.FC = (): JSX.Element => {
  const [data, setData] = useState<CredentialType | any>();
  const [total, setTotal] = useState<number>();
  const {view_and_add_api_credentials} = useAppSelector(
    (state) => state.common.userData
  );
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const navigate = useNavigate();
  
  useEffect(() => {   
    
    handleAPICredentials();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAPICredentials = useCallback( async(page: number = 1) => {
    setFetching(true);
    const res = await fetchApiCredentials(page);
    if (res.ok) {
      setData(res.data?.data);
      setTotal(res.data?.total_count);
    }
    setFetching(false);
  },[]);

  const handlePagination = (page: number) => {
    handleAPICredentials(page);
  };
  
  const handleCreateAPI = throttle( async() => {
    const res = await createApiKey();
    if (res.ok) {
      navigate(PAGE_URLS.CREATE_API, {
        state: {
          ...res?.data?.data,
        },
      });
    } else {
      message.error(res.data.message || res.data.errors);
    }
  }, 3500); 

  const columns: ColumnsType<DataType> = [
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'date',
    },
    {
      title: 'API Key',
      dataIndex: 'api_key',
      key: 'api',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expires_on',
      key: '1',
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: '2',
      render: (val:boolean) => (val  ? <span style={{ color: '#3dbd7d' }}>Active</span> : <span style={{ color: '#f54242' }}>Inactive</span>)
      },
  ];

  return (
    <div
      className="api-credentials-managment"
      data-testid="api-credentials-managment"
    >
      <Row>
        <Col span={24}>
          <div className="api-credentials-managment-wrapper">
            <div className="api-credentials-managment-wrapper-content">
              <h3>Generated Credentials</h3>
              {view_and_add_api_credentials&&<Button size="large" type="primary" onClick={handleCreateAPI}>
                {' '}
                <PlusCircleOutlined
                  style={{ fontSize: '16px', color: '#fff' }}
                />{' '}
                Create Credentials
              </Button>}
            </div>
            <div className="api-credentials-managment-wrapper-table">
              <Table
                rowKey={'api_key'}
                columns={columns}
                dataSource={data}
                loading={isFetching}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 12,
                  total: total,
                  showSizeChanger: false,
                  showTotal: (total, range) => (
                    <span className="table-pagination-total">
                      {range[0]}-{range[1]} of {total} items
                    </span>
                  ),
                  onChange: handlePagination,
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ApiCredentialsManagment;
